<template>
  <div>
    <div v-if="cyclesList.length == 0">
      <h5>
        Esta Competencia Genérica no tiene
        {{
          $getVisibleNames(
            "manual.indicador_de_logro",
            true,
            "Indicadores de Logro"
          )
        }}
        <!-- Indicadores de Logro -->
        creados.
      </h5>
    </div>
    <div
      v-can="'mesh.add_performanceindicator'"
      style="text-align: left"
      class="mr-1 my-1"
    >
      <b-button
        v-if="allows_crud"
        size="sm"
        :title="`Agregar ${$getVisibleNames(
          'manual.indicador_de_logro',
          false,
          'Indicador de Logro'
        )}`"
        @click="
          $bvModal.show(
            `modal-create-performance-indicator-${InstitutionCompetence.id}`
          )
        "
      >
        <b-icon-plus></b-icon-plus>Agregar
        {{
          $getVisibleNames(
            "manual.indicador_de_logro",
            false,
            "Indicador de Logro"
          )
        }}
        <!-- Indicador de Logro -->
      </b-button>
      <b-modal
        :id="`modal-create-performance-indicator-${InstitutionCompetence.id}`"
        :key="`modal-create-performance-indicator-${InstitutionCompetence.id}`"
        hide-footer
        :title="`Crear ${$getVisibleNames(
          'manual.indicador_de_logro',
          false,
          'Indicador de Logro'
        )}`"
      >
        <PerformanceIndicatorForm
          :institution_competence_id="InstitutionCompetence.id"
          :cycle_id="1"
          :show_title="false"
          @created="
            $bvModal.hide(
              `modal-create-performance-indicator-${InstitutionCompetence.id}`
            )
          "
        ></PerformanceIndicatorForm>
      </b-modal>
    </div>
    <table>
      <thead>
        <tr>
          <th v-for="cycle in cyclesList" :key="cycle.id">
            <div
              style="float: left"
              v-bind:style="[
                allows_crud
                  ? { width: '80%', marginLeft: '1.5rem' }
                  : { width: '100%' },
              ]"
            >
              {{
                $getVisibleNames(
                  "manual.indicador_de_logro",
                  false,
                  "Indicador de Logro"
                )
              }}
              <!-- Indicador de Logro -->
              <br />
              {{ $getVisibleNames("mesh.cycle", false, "Ciclo Formativo") }}:
              {{ cycle.name }}
            </div>

            <div
              v-can="'mesh.add_performanceindicator'"
              style="float: right"
              class="mr-1 my-1"
            >
              <b-button
                v-if="allows_crud"
                size="sm"
                variant="none"
                :title="`Agregar ${$getVisibleNames(
                  'manual.indicador_de_logro',
                  false,
                  'Indicador de Logro'
                )}`"
                @click="
                  $bvModal.show(
                    `modal-create-performance-indicator-${InstitutionCompetence.id}-${cycle.id}`
                  )
                "
              >
                <b-icon-plus></b-icon-plus>
              </b-button>
              <b-modal
                :id="`modal-create-performance-indicator-${InstitutionCompetence.id}-${cycle.id}`"
                :key="`modal-create-performance-indicator-${InstitutionCompetence.id}-${cycle.id}`"
                hide-footer
                :title="`Crear ${$getVisibleNames(
                  'manual.indicador_de_logro',
                  false,
                  'Indicador de Logro'
                )}`"
              >
                <PerformanceIndicatorForm
                  :institution_competence_id="InstitutionCompetence.id"
                  :cycle_id="cycle.id"
                  :show_title="false"
                  @created="
                    $bvModal.hide(
                      `modal-create-performance-indicator-${InstitutionCompetence.id}-${cycle.id}`
                    )
                  "
                ></PerformanceIndicatorForm>
              </b-modal>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td v-for="cycle in cyclesList" :key="cycle.id">
            <template
              v-for="indicator in performanceIndicatorList.filter(
                (x) => x.cycle == cycle.id
              )"
            >
              <ul :key="indicator.id">
                <li>
                  {{ indicator.cycle }}.{{ indicator.order }}
                  {{ indicator.action }}
                  {{ indicator.content }}
                  {{ indicator.condition }}
                  <b-button
                    v-can="'mesh.change_performanceindicator'"
                    v-if="allows_crud"
                    size="sm"
                    variant="none"
                    style="float: right"
                    @click="
                      $bvModal.show(
                        `modal-update-performance-indicator-${indicator.id}`
                      )
                    "
                  >
                    <b-icon-file-earmark-text></b-icon-file-earmark-text>
                  </b-button>
                </li>
              </ul>
              <b-modal
                :id="`modal-update-performance-indicator-${indicator.id}`"
                :key="`modal-update-performance-indicator-${indicator.id}`"
                hide-footer
                :title="`Modificar ${$getVisibleNames(
                  'manual.indicador_de_logro',
                  false,
                  'Indicador de Logro'
                )}`"
              >
                <PerformanceIndicatorForm
                  :institution_competence_id="indicator.institution_competence"
                  :cycle_id="indicator.cycle"
                  :PerformanceIndicator="indicator"
                  :show_delete_button="true"
                  :show_title="false"
                  @updated="
                    $bvModal.hide(
                      `modal-update-performance-indicator-${indicator.id}`
                    )
                  "
                  @deleted="
                    $bvModal.hide(
                      `modal-update-performance-indicator-${indicator.id}`
                    )
                  "
                ></PerformanceIndicatorForm>
              </b-modal>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "PerformanceIndicators",
  components: {
    PerformanceIndicatorForm: () => import("./PerformanceIndicatorForm"),
  },
  props: {
    InstitutionCompetence: {
      type: Object,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      performanceIndicators: names.PERFORMANCE_INDICATORS,
      cycles: names.CYCLES,
    }),
    performanceIndicatorList() {
      return this.performanceIndicators.filter(
        (x) => x.institution_competence == this.InstitutionCompetence.id
      );
    },
    cyclesList() {
      let list = [];
      this.cycles.forEach((element) => {
        if (
          this.performanceIndicatorList.findIndex(
            (x) => x.cycle == element.id
          ) != -1
        )
          list.push(element);
      });
      return list;
    },
  },
  created() {
    this.$store.dispatch(
      names.FETCH_PERFORMANCE_INDICATORS,
      this.InstitutionCompetence.id
    );
    this.$store.dispatch(names.FETCH_CYCLES);
  },
};
</script>

<style scoped>
ul {
  padding-left: 1.3rem;
}

li {
  text-align: justify;
  padding-left: 0.1rem;
  padding-right: 1rem;
}

th {
  background-color: var(--primary-color);
  color: white;
  width: 33%;
}
td {
  padding-top: 12px;
  vertical-align: top;
}
table {
  width: 100%;
}
table,
th,
td {
  border: 1px solid black;
}
</style>